import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { lastValueFrom } from 'rxjs';
import { AccountService } from '../account/account.service';
import { PartnerService } from '../partner/partner.service';
import { PractitonerService } from '../practitioner/practitoner.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private accountService: AccountService,
    private partnerService: PartnerService,
    private practitionerService: PractitonerService,
    private storage: Storage,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async initializeAppData() {
    await this.storage.create();
    // init get juice account data, partner data and partner configs handle redirection to authentication
    const juiceId = await this.accountService.getJuiceId();

    if (juiceId) {
      await this.accountService.getAccountFromAPI(juiceId);
      // TODO setup app based on logged in partner/practitioner
      // TODO get both partner/prac details from BE to setup app
      await lastValueFrom(this.partnerService.getPartnerByJuiceId(juiceId));

      await lastValueFrom(
        this.practitionerService.getPractitionerFromJuiceId(juiceId)
      );

      // TODO get the accounts current configuration for the app to route them appropriately
    }
  }
}
