import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImageUploadResponse } from '../image/image.service';
import {
  OnboardingResponse,
  StripeAccountResponse,
} from '../partner/partner.service';

export interface Practitioner {
  practitioner_id: string;
  partner_id: string;
  juice_id: string;
  stripe_id: string;
  phone_number: string;
  email: string;
  profile_picture: string;
  account: {
    first_name: string;
    last_name: string;
    pronouns: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export interface PractitonerOnboardingRequest {
  practitioner_id: string;
  refresh_url: string;
  return_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class PractitonerService {
  public referralCode: string;
  public currentPractitioner: Practitioner;
  public profileImage: ImageUploadResponse;
  private baseUrl = environment.apiUrl;
  constructor(private storage: Storage, private http: HttpClient) {}

  public setReferralCode(referralCode: string) {
    return this.storage.set('referralCode', referralCode);
  }

  public getReferralCode() {
    return this.storage.get('referralCode');
  }

  createPractitioner(payload: Partial<Practitioner>) {
    const endpoint = this.baseUrl + '/practitioner/create/';
    return this.http
      .post<{ practitioner: Practitioner }>(endpoint, payload, {
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          this.currentPractitioner = res.practitioner;
          return res;
        })
      );
  }

  getReferringPartner(referralCode: string) {
    const endpoint =
      this.baseUrl +
      `/partner/get-referring-partner/?referral_code=${referralCode}`;
    return this.http.get<{ referring_partner: { partner_id: string } }>(
      endpoint,
      { withCredentials: false }
    );
  }

  getPractitionerFromJuiceId(juice_id: string) {
    const endpoint = this.baseUrl + `/practitioner/?juice_id=${juice_id}`;
    return this.http
      .get<Practitioner>(endpoint, { withCredentials: true })
      .pipe(
        map((res) => {
          if (res.partner_id) {
            this.currentPractitioner = res;
          }
          return res; // res can be {}
        })
      );
  }

  updatePractitioner(practitoner_id: string, payload: Partial<Practitioner>) {
    const endpoint = this.baseUrl + `/practitioner/${practitoner_id}`;
    return this.http
      .patch<Practitioner>(endpoint, payload, { withCredentials: true })
      .pipe(
        map((res) => {
          this.currentPractitioner = res;
          return res;
        })
      );
  }

  startOnboarding() {
    const endpoint = this.baseUrl + `/practitioner/onboarding/`;
    const body: PractitonerOnboardingRequest = {
      practitioner_id: this.currentPractitioner.practitioner_id,
      return_url: 'https://getyourjuice.app/dashboard',
      refresh_url: 'https://getyourjuice.app/dashboard',
    };
    return this.http.post<OnboardingResponse>(endpoint, body, {
      withCredentials: true,
    });
  }

  getStripeAccount() {
    const endpoint =
      this.baseUrl +
      `/practitioner/${this.currentPractitioner.practitioner_id}/stripe/account`;

    return this.http.get<StripeAccountResponse>(endpoint, {
      withCredentials: true,
    });
  }

  attachExternalAccount(body: any) {
    const endpoint =
      this.baseUrl +
      `/practitioner/${this.currentPractitioner.practitioner_id}/stripe/external_accounts`;

    return this.http.post(endpoint, body, { withCredentials: true });
  }
}
