import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../account/account.service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService  {
  constructor(private router: Router, private accountService: AccountService) {}
  canActivate() {
    return this.accountService.getTokenFromStorage().then((res) => {
      if (!res) {
        return this.router.createUrlTree(['/juice-letter']);
      } else {
        return true;
      }
    });
  }
}
