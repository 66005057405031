import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface EmailPassword {
  email: string;
  password: string;
}

export interface AccountRegisterRequest {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  registerUser(body: EmailPassword) {
    const endpoint = this.baseUrl + '/account/register';
    return this.http.post(endpoint, body, { withCredentials: false });
  }

  login(body: EmailPassword) {
    const endpoint = this.baseUrl + '/account/login';
    return this.http.post(endpoint, body, { withCredentials: false });
  }

  changePassword(body: any) {
    const endpoint = this.baseUrl + '/account/change-password';
    return this.http.post(endpoint, body, { withCredentials: true });
  }

  acceptTos(juice_id: string) {
    const endpoint = this.baseUrl + '/account/tos/accept';
    return this.http.post(endpoint, { juice_id }, { withCredentials: true });
  }
}
