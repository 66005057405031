import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'juice-underline',
  templateUrl: './juice-underline.component.html',
  styleUrls: ['./juice-underline.component.scss'],
})
export class JuiceUnderlineComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
