<ion-content>
  <ion-row>
    <ion-col>
      <h4 class="header ion-text-center">Change Password</h4>
    </ion-col>
  </ion-row>
  <ion-row class="ion-justify-content-center margin-around">
    <ion-col [formGroup]="authForm">
      <ion-input
        class="juice-input"
        [ngClass]="{
          'juice-input-error':
            authForm.get('currentPassphrase').touched &&
            authForm.get('currentPassphrase').errors
        }"
        formControlName="currentPassphrase"
        type="password"
        labelPlacement="stacked"
        fill="outline"
        label="Current Passphrase"
      ></ion-input>
      <div
        class="form-error"
        *ngIf="
          authForm.get('currentPassphrase').touched &&
          authForm.get('currentPassphrase')?.hasError('required')
        "
      >
        Current Passphrase is required
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="ion-justify-content-center margin-around">
    <ion-col [formGroup]="authForm">
      <ion-input
        class="juice-input"
        [ngClass]="{
          'juice-input-error':
            authForm.get('newPassphrase').touched &&
            authForm.get('newPassphrase').errors
        }"
        formControlName="newPassphrase"
        type="password"
        labelPlacement="stacked"
        fill="outline"
        label="New Passphrase"
      ></ion-input>

      <div
        class="form-error"
        *ngIf="
          authForm.get('newPassphrase').touched &&
          authForm.get('newPassphrase')?.hasError('minlength')
        "
      >
        Password must be atleast 10 characters
      </div>
      <div
        class="form-error"
        *ngIf="
          authForm.get('newPassphrase').touched &&
          authForm.get('newPassphrase')?.hasError('hasNumber')
        "
      >
        Password must have at least 1 number
      </div>
      <div
        class="form-error"
        *ngIf="
          authForm.get('newPassphrase').touched &&
          authForm.get('newPassphrase')?.hasError('hasSmallCase')
        "
      >
        Password must have at least 1 lower case letter
      </div>
      <div
        class="form-error"
        *ngIf="
          authForm.get('newPassphrase').touched &&
          authForm.get('newPassphrase')?.hasError('hasCapitalCase')
        "
      >
        Password must have at least 1 upper case letter
      </div>
      <div
        class="form-error"
        *ngIf="
          authForm.get('newPassphrase').touched &&
          authForm.get('newPassphrase')?.hasError('hasSpecialCharacters')
        "
      >
        Password must have at least 1 special character
      </div>
      <!-- </ion-item> -->
    </ion-col>
  </ion-row>
  <ion-row class="ion-justify-content-center margin-around">
    <ion-col [formGroup]="authForm">
      <ion-input
        class="juice-input"
        [ngClass]="{
          'juice-input-error':
            authForm.get('confirmNewPassphrase').touched &&
            authForm.get('confirmNewPassphrase').errors
        }"
        formControlName="confirmNewPassphrase"
        type="password"
        labelPlacement="stacked"
        fill="outline"
        label="Re-enter New Passphrase"
      ></ion-input>
      <div
        class="form-error"
        *ngIf="
          authForm.get('confirmNewPassphrase').touched &&
          authForm.get('confirmNewPassphrase')?.hasError('NoPassswordMatch')
        "
      >
        Passphrases do not match
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="form-buttons">
      <juice-button class="cancel-button" (click)="closeModal()"
        >Cancel</juice-button
      >
      <juice-button (click)="submit()">Submit</juice-button>
    </ion-col>
  </ion-row>
</ion-content>
