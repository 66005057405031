import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private storage: Storage) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    // const authToken = this.auth.getAuthorizationToken();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.

    // send cloned request with header to the next handler.
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const token = await this.storage.get('token');

    if (!token && req.withCredentials) {
      return next.handle(req).toPromise();
    }

    const headers = new HttpHeaders().set('Authorization', token);
    const authReq = req.clone({
      headers,
      withCredentials: false,
    });
    if (!req.withCredentials) {
      return next.handle(req).toPromise();
    } else {
      // Important: Note the .toPromise()
      // authReq.withCredentials = false;
      return next.handle(authReq).toPromise();
    }
  }
}
