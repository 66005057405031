<ion-content>
  <ion-row class="question">
    <ion-col>
      <h4>Upload a picture of your business.</h4>
      <p>
        Choose an image that represents your business most authentically. You
        can come back and change it as often as you'd like!
      </p>
    </ion-col>
  </ion-row>

  <ion-row class="file-uploader-container">
    <input
      type="file"
      accept="image/*"
      (change)="getFile($event)"
      class="hidden-input"
      #imageUploader
    />

    <ion-col class="image-col">
      <div
        class="dummy-image-container"
        [style.background-image]="'url(' + imageUrl + ')'"
        (click)="imageUploader.click()"
      >
        <p *ngIf="!imageUrl">Tap to upload</p>
      </div>
      <div class="change-image-button" *ngIf="imageUrl">
        <juice-button class="change-image" (click)="imageUploader.click()">
          Change image
        </juice-button>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="text-below-input">
    <ion-col>
      <p>We recommend using a horizontal picture for the best look.</p>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="controls-container">
        <juice-button class="cancel-button" (click)="closeModal()"
          >Cancel</juice-button
        >
        <juice-button *ngIf="imageUrl" (click)="completed()">
          Done
        </juice-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
