<ion-content>
  <ion-header class="product-header">
    <h4>Business Hours</h4>
  </ion-header>
  <ion-row>
    <ion-col>
      <p class="ion-text-center tag-line">
        Click on a day to make any changes
      </p>
    </ion-col>
  </ion-row>

  <ng-container *ngFor="let day of days">
    <div
      *ngIf="!loading"
      (click)="openEditModal(day.day_of_week)"
      class="day-wrapper"
    >
      <div class="left">
        <div>
          <ion-toggle
            (click)="$event.stopPropagation()"
            (ionChange)="toggledDay($event, day.day_of_week)"
            [checked]="day.config.length"
          ></ion-toggle>
        </div>
        <h4 class="day-name">{{ day.name }}</h4>
      </div>
      <div class="right">
        <ng-container *ngIf="day.config.length">
          <div class="right-bottom" *ngFor="let config of day.config">
            <span>{{ config.open }} - {{ config.close }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!day.config.length">
          <span>Closed</span>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ion-row>
    <ion-col class="form-buttons">
      <juice-button (click)="closeModal()">Back</juice-button>
    </ion-col>
  </ion-row>
</ion-content>
