import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BusinessHoursComponent } from './business-hours/business-hours.component';
import { InformationComponent } from './information/information.component';
import { JuiceButtonComponent } from './juice-button/juice-button.component';
import { JuiceInputComponent } from './juice-input/juice-input.component';
import { JuiceUnderlineComponent } from './juice-underline/juice-underline.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProfilePictureUploadComponent } from './profile-picture-upload/profile-picture-upload.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditBusinessHoursComponent } from './edit-business-hours/edit-business-hours.component';

const SharedComponentsList = [
  JuiceButtonComponent,
  NavigationComponent,
  JuiceInputComponent,
  JuiceUnderlineComponent,
  ProfilePictureUploadComponent,
  InformationComponent,
  BusinessHoursComponent,
  ChangePasswordComponent,
  EditBusinessHoursComponent,
];

@NgModule({
  declarations: SharedComponentsList,
  exports: SharedComponentsList,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
})
export class SharedComponentsModule {}
