import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonInput } from '@ionic/angular';

export interface InputConfig {
  controlName: string;
  label: string;
  type: string;
  errorMessage: string;
  disabled?: boolean;
  minlength?: number;
  maxlength?: number;
  placeholder?: string;
  autocomplete?: string;
  helperText?: string;
}

@Component({
  selector: 'app-juice-input',
  templateUrl: './juice-input.component.html',
  styleUrls: ['./juice-input.component.scss'],
})
export class JuiceInputComponent {
  @ViewChild('ionInput') ionInput: IonInput;
  @Input() group: FormGroup = new FormGroup({});
  @Input() controlName: string;
  @Input() label: string;
  @Input() type: string;
  @Input() errorMessage: string;
  @Input() inputmode: string;
  @Input() placeholder: string;
  @Input() maxlength?: number;
  @Input() minlength?: number;
  @Input() autofocus: boolean = false;
  @Input() config?: InputConfig;
  @Input() helperText?: string;
  // @Input() disabled: boolean = false;
  constructor() {}
}
