import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AccountService } from '../../services/account/account.service';
import { CustomValidators } from '../../validators/custom-validators/custom-validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public passwordError: string = '';
  public authForm: FormGroup = new FormGroup(
    {
      currentPassphrase: new FormControl(null, [Validators.required]),
      newPassphrase: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        CustomValidators.patternValidator(new RegExp(/\d/), {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(new RegExp(/[A-Z]/), {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(new RegExp(/[a-z]/), {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          new RegExp(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/),
          {
            hasSpecialCharacters: true,
          }
        ),
      ]),
      confirmNewPassphrase: new FormControl(null, [Validators.required]),
    },
    {
      validators: CustomValidators.passwordMatchValidator,
    }
  );

  constructor(
    private modalController: ModalController,
    private accountService: AccountService,
    private authenicationService: AuthenticationService,
    private toast: ToastController
  ) {}

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
  }

  async submit() {
    if (!this.validateForm()) {
      return;
    }
    const juice_id = await this.accountService.getJuiceId();
    // Send to API
    const payload = {
      existing_password: this.authForm.value['currentPassphrase'],
      new_password: this.authForm.value['newPassphrase'],
      juice_id,
    };
    this.authenicationService.changePassword(payload).subscribe({
      next: async (res) => {
        const toast = await this.toast.create({
          message: 'Passphrase changed successfully!',
          duration: 5000,
          position: 'bottom',
          color: 'success',
        });
        toast.present();
        // TODO in the future log them out or update token
      },
      error: async (err) => {
        const message = err.error.message || 'Please try again.';
        const toast = await this.toast.create({
          message: `Unable to change password. ${message}`,
          duration: 5000,
          position: 'bottom',
          color: 'danger',
        });
        toast.present();
      },
    });
  }

  validateForm() {
    this.authForm.markAllAsTouched();
    return this.authForm.valid;
  }
}
