import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { NavigationService } from './shared/services/navigation/navigation.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public loading: boolean = false;
  public showSettings: boolean = true;
  public showNav: boolean = false;
  public showLogo: boolean = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    this.navigationService.getNavigationState().subscribe((navState) => {
      this.showNav = navState;
      this.cdr.detectChanges();
    });
    this.navigationService.getLogoState().subscribe((logoState) => {
      this.showLogo = logoState;
      this.cdr.detectChanges();
    });
    setTimeout(async () => {
      await SplashScreen.hide();
    }, 1000);
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2

        const slug = event.url.split('.app').pop();
        if (slug) {
          Browser.close().catch();
          this.router.onSameUrlNavigation = 'reload';
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
      });
    });
  }
}
