<ion-content>
  <ion-row>
    <ion-col>
      <h4 class="question ion-text-center">Tell us about your business</h4>
    </ion-col>
  </ion-row>
  <ng-container *ngIf="showStepOne">
    <ion-row *ngFor="let config of infoFormConfig">
      <ion-col class="input-fields-container">
        <app-juice-input
          [group]="infoForm"
          [controlName]="config.controlName"
          [label]="config.label"
          [type]="config.type"
          [errorMessage]="config.errorMessage"
          [minlength]="config.minlength"
          [maxlength]="config.maxlength"
        ></app-juice-input>
      </ion-col>
    </ion-row>
  </ng-container>
  <ng-container *ngIf="!showStepOne">
    <ion-row *ngIf="!showStepOne">
      <ion-col>
        <h5>Enter your business address</h5>
      </ion-col>
    </ion-row>

    <ion-row *ngFor="let config of addressConfig">
      <ion-col>
        <app-juice-input
          [group]="addressForm"
          [controlName]="config.controlName"
          [label]="config.label"
          [type]="config.type"
          [errorMessage]="config.errorMessage"
        ></app-juice-input>
      </ion-col>
    </ion-row>
  </ng-container>
  <ion-row>
    <ion-col class="form-buttons">
      <juice-button *ngIf="!showStepOne" (click)="goBack()">Back</juice-button>
      <juice-button class="cancel-button" (click)="closeModal()"
        >Cancel</juice-button
      >
      <juice-button (click)="submitInfo()">Done</juice-button>
    </ion-col>
  </ion-row>
</ion-content>
