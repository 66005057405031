<ion-app>
  <div class="top-nav">
    <div *ngIf="showLogo" class="logo">
      <img src="assets/juice.logo.png" alt="" />
    </div>
    <div *ngIf="showNav" routerLink="/settings" class="settings">
      <img src="assets/settings.svg" alt="" />
    </div>
  </div>
  <ion-content *ngIf="!loading">
    <router-outlet></router-outlet>
  </ion-content>
  <app-navigation *ngIf="showNav"></app-navigation>
</ion-app>
