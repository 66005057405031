import { Component, OnInit } from '@angular/core';
import {
  Partner,
  PartnerService,
} from '../../services/partner/partner.service';
import {
  Practitioner,
  PractitonerService,
} from '../../services/practitioner/practitoner.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public partner: Partner;
  public displayProfilePicureUrl: string;
  public practitioner: Practitioner;
  constructor(
    private partnerService: PartnerService,
    private practitionerService: PractitonerService
  ) {}

  ngOnInit() {
    this.partner = this.partnerService.currentPartner;
    this.practitioner = this.practitionerService.currentPractitioner;
    if (this.partner?.photos?.length && this.partner.photos[0]?.key) {
      this.displayProfilePicureUrl = this.partner.photos[0].location;
    } else if (this.practitioner?.profile_picture) {
      this.displayProfilePicureUrl = this.practitioner.profile_picture;
    }
  }
}
