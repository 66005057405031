import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { JuiceEvent } from 'src/app/shared/services/calendar/calendar.service';
import { environment } from 'src/environments/environment';
import { ImageUploadResponse } from '../image/image.service';
import { Practitioner } from '../practitioner/practitoner.service';

export interface Partner {
  partner_id: string;
  juice_id: string;
  stripe_id: string;
  requirements_due: boolean;
  name: string;
  phone_number: string;
  business_type: string;
  website: string;
  email: string;
  description: string;
  photos: ImageUploadResponse[];
  createdAt: string;
  updatedAt: string;
  addresses: any[];
  settings: {
    partner: {
      event_interval: number;
      payout_frequency: number;
      practitioner_share: number;
    };
  }; // TODO add types
  practitioners: Practitioner[];
  account: {
    first_name: string;
    last_name: string;
  };
}

export interface StripeAccountResponse {
  partner_id: string;
  stripe_id: string;
  stripe_account: StripeAccount;
}

export interface StripeAccount {
  id: string;
  object: string;
  business_profile: {
    mcc: string;
    name: string;
    product_description: string;
    support_address: string;
    support_email: string;
    support_phone: string;
    support_url: string;
    url: string;
  };
  business_type: string; // break down into enums
  capabilities: { card_payments: string; transfers: string };
  charges_enabled: boolean;
  company: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    directors_provided: boolean;
    executives_provided: boolean;
    name: string;
    owners_provided: boolean;
    phone: string;
    tax_id_provided: boolean;
    verification: {
      document: {
        back: any;
        details: any;
        details_code: any;
        front: any;
      };
    };
  };
  country: string;
  created: number;
  default_currency: string;
  details_submitted: boolean;
  email: string;
  external_accounts: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  future_requirements: StripeAccountRequirements;
  individual: {
    id: string;
    object: string;
    account: string;
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    created: number;
    dob: { day: number; month: number; year: number };
    email: string;
    first_name: string;
    future_requirements: StripeAccountRequirements;
    id_number_provided: boolean;
    last_name: string;
    metadata: {};
    phone: string;
    relationship: {
      director: boolean;
      executive: boolean;
      owner: boolean;
      percent_ownership: any;
      representative: boolean;
      title: any;
    };
    requirements: StripeAccountRequirements;
    ssn_last_4_provided: boolean;
    verification: {
      additional_document: {
        back: any;
        details: any;
        details_code: any;
        front: any;
      };
      details: null;
      details_code: null;
      document: {
        back: null;
        details: null;
        details_code: null;
        front: string;
      };
      status: string;
    };
  };
  metadata: {};
  payouts_enabled: boolean;
  requirements: StripeAccountRequirements;
  settings: {
    bacs_debit_payments: {};
    branding: {
      icon: null;
      logo: null;
      primary_color: null;
      secondary_color: null;
    };
    card_issuing: { tos_acceptance: { date: null; ip: null } };
    card_payments: {
      decline_on: { avs_failure: false; cvc_failure: false };
      statement_descriptor_prefix: null;
    };
    dashboard: { display_name: null; timezone: 'Etc/UTC' };
    payments: {
      statement_descriptor: null;
      statement_descriptor_kana: null;
      statement_descriptor_kanji: null;
    };
    payouts: {
      debit_negative_balances: false;
      schedule: { delay_days: 2; interval: 'daily' };
      statement_descriptor: null;
    };
    sepa_debit_payments: {};
  };
  tos_acceptance: { date: number };
  type: string;
}

export interface StripeAccountRequirements {
  alternatives: string[];
  current_deadline: number;
  currently_due: string[];
  disabled_reason: string;
  errors: string[];
  eventually_due: string[];
  past_due: string[];
  pending_verification: string[];
}

export interface PartnerOnboardingRequest {
  partner_id: string;
  refresh_url: string;
  return_url: string;
}

export interface OnboardingResponse {
  created: number;
  expires_at: number;
  object: string;
  url: string;
}

export enum StripeRequirements {
  external_account = 'external_account',
}

export interface PartnerConfig {
  config_id: string;
  open: string;
  close: string;
  day_of_week: number;
}

export interface PartnerOverride {
  alt_close: string;
  alt_open: string;
  closed: boolean;
  createdAt: string;
  override_date: string;
  override_id: string;
  partner_id: string;
  updatedAt: string;
}

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  public profileImage: ImageUploadResponse;
  public currentPartner: Partner;
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  createPartnerAccount(payload: any) {
    const endpoint = this.baseUrl + '/partner/create/';
    return this.http
      .post<Partner>(endpoint, payload, { withCredentials: true })
      .pipe(
        map((res) => {
          this.currentPartner = res;
          return res;
        })
      );
  }

  updatePartnerDetails(partner_id, payload) {
    const endpoint = this.baseUrl + `/partner/${partner_id}`;

    return this.http
      .patch<Partner>(endpoint, payload, { withCredentials: true })
      .pipe(
        map((res) => {
          this.currentPartner = res;
          return res;
        })
      );
  }

  getPartnerByJuiceId(juice_id: string) {
    const endpoint = `${this.baseUrl}/partner/?juice_id=${juice_id}`;
    return this.http.get<Partner>(endpoint, { withCredentials: true }).pipe(
      map((res) => {
        if (res.partner_id) {
          this.currentPartner = res;
        }
        return res; // res can be {}
      })
    );
  }

  getPublicPartnerDetails(partnerId: string) {
    const endpoint = `${this.baseUrl}/public/partner/${partnerId}`;
    return this.http.get<{ partner: Partner }>(endpoint, {
      withCredentials: false,
    });
  }

  startOnboarding() {
    const endpoint = this.baseUrl + `/partner/onboarding/`;
    const body: PartnerOnboardingRequest = {
      partner_id: this.currentPartner.partner_id,
      return_url: 'https://getyourjuice.app/dashboard',
      refresh_url: 'https://getyourjuice.app/dashboard',
    };
    return this.http.post<OnboardingResponse>(endpoint, body, {
      withCredentials: true,
    });
  }

  getStripeAccount() {
    const endpoint =
      this.baseUrl +
      `/partner/${this.currentPartner.partner_id}/stripe/account`;

    return this.http.get<StripeAccountResponse>(endpoint, {
      withCredentials: true,
    });
  }

  attachExternalAccount(body: any) {
    const endpoint =
      this.baseUrl +
      `/partner/${this.currentPartner.partner_id}/stripe/external_accounts`;

    return this.http.post(endpoint, body, { withCredentials: true });
  }

  logout() {
    this.currentPartner = undefined;
    this.profileImage = undefined;
  }

  getServiceAvailabilities(partnerId, productId, date) {
    const endpoint = `${this.baseUrl}/public/events/service/availabilities?partner_id=${partnerId}&product_id=${productId}&service_date=${date}`;
    return this.http.get<string[]>(endpoint, { withCredentials: false });
  }

  getClassAvailabilities(partnerId, productId, start_date, end_date?) {
    const endpoint = `${this.baseUrl}/public/events/class/availabilities?partner_id=${partnerId}&product_id=${productId}&start_date=${start_date}`;
    return this.http.get<JuiceEvent[]>(endpoint, { withCredentials: false });
  }

  getPartnerConfig(partner_id: string) {
    // TODO add types
    const endpoint = `${this.baseUrl}/config?partner_id=${partner_id}`;
    return this.http.get<any[]>(endpoint, { withCredentials: true });
  }

  createPartnerConfig(reqBody: any) {
    const endpoint = `${this.baseUrl}/config/create`;
    return this.http.post(endpoint, reqBody, { withCredentials: true });
  }

  updatePartnerConfig(config_id: string, reqBody: any) {
    const endpoint = `${this.baseUrl}/config/${config_id}`;
    return this.http.patch(endpoint, reqBody, { withCredentials: true });
  }

  deletePartnerConfig(configId: string) {
    const endpoint = `${this.baseUrl}/config/${configId}`;
    return this.http.delete(endpoint, { withCredentials: true });
  }

  getAllBusinessOverrides(partner_id: string) {
    const endpoint = `${this.baseUrl}/override?partner_id=${partner_id}`;
    return this.http.get<PartnerOverride[]>(endpoint, {
      withCredentials: true,
    });
  }

  createBusinessOverride(body: any) {
    const endpoint = `${this.baseUrl}/override/create`;
    return this.http.post<PartnerOverride[]>(endpoint, body, {
      withCredentials: true,
    });
  }

  deleteOverride(override_id: string) {
    const endpoint = `${this.baseUrl}/override/${override_id}`;
    return this.http.delete<PartnerOverride[]>(endpoint, {
      withCredentials: true,
    });
  }

  generateReferralCode() {
    const endpoint = `${this.baseUrl}/partner/${this.currentPartner.partner_id}/create-practitioner-link`;

    return this.http.get<{ referral_code: string }>(endpoint, {
      withCredentials: true,
    });
  }

  updatePartnerSettings(juice_id: string, settings: any) {
    const endpoint = `${this.baseUrl}/setting/${juice_id}`;

    return this.http.patch(endpoint, settings, {
      withCredentials: true,
    });
  }
}
