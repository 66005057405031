<ion-content>
  <ion-header class="product-header">
    <h4>{{ days[dayOfWeek] }}</h4>
  </ion-header>
  <ion-row>
    <ion-col>
      <p class="ion-text-center tag-line">Set up your business hours</p>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="!configs.length">
    <ion-col> <h4 class="ion-text-center">Closed</h4> </ion-col>
  </ion-row>

  <ng-container *ngIf="configs.length">
    <ion-row *ngFor="let config of todaysConfig">
      <ion-col class="ion-text-center">
        <h4 class="time" id="open-config">
          <juice-underline>
            {{ config.open | date: "shortTime" }}
          </juice-underline>
        </h4>
      </ion-col>
      <ion-col class="ion-text-center">
        <h4 class="time" id="close-config">
          <juice-underline>
            {{ config.close | date: "shortTime" }}
          </juice-underline>
        </h4>
      </ion-col>

      <ion-popover trigger="open-config">
        <ng-template>
          <ion-datetime
            #openTime
            [value]="config.open"
            presentation="time"
            (ionChange)="changeOpen(config, openTime.value)"
            size="cover"
            showDefaultButtons="true"
          >
          </ion-datetime>
        </ng-template>
      </ion-popover>

      <ion-popover trigger="close-config">
        <ng-template>
          <ion-datetime
            #closeTime
            presentation="time"
            [value]="config.close"
            (ionChange)="changeClose(config, closeTime.value)"
            size="cover"
            showDefaultButtons="true"
          >
          </ion-datetime>
        </ng-template>
      </ion-popover>
    </ion-row>
  </ng-container>

  <ion-row>
    <ion-col class="form-buttons">
      <!-- <juice-button (click)="addNewTime()">Add a time</juice-button> -->
      <juice-button (click)="close()">Done</juice-button>
    </ion-col>
  </ion-row>
</ion-content>
