<ion-row class="nav-container">
  <ion-col routerLink="/calendar" class="nav-item">
    <ion-icon name="calendar-outline"></ion-icon>
    <div routerLinkActive="border-box-active" class="border-box"></div>
  </ion-col>
  <ion-col routerLink="/dashboard" class="nav-item">
    <div
      class="image-container"
      [style.background-image]="'url(' + displayProfilePicureUrl + ')'"
    ></div>
    <div routerLinkActive="border-box-active" class="border-box"></div>
  </ion-col>
  <ion-col routerLink="/standalone-payments" class="nav-item">
    <ion-icon name="card-outline"></ion-icon>
    <div routerLinkActive="border-box-active" class="border-box"></div>
  </ion-col>
</ion-row>
