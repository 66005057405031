import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'juice-button',
  templateUrl: './juice-button.component.html',
  styleUrls: ['./juice-button.component.scss'],
})
export class JuiceButtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
