import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private toggleNav$ = new BehaviorSubject<boolean>(false);
  private toggleLogo$ = new BehaviorSubject<boolean>(true);

  constructor() {}

  toggleNavigationState(state: boolean) {
    this.toggleNav$.next(state);
  }

  getNavigationState() {
    return this.toggleNav$.asObservable();
  }

  toggleLogoState(state: boolean) {
    this.toggleLogo$.next(state);
  }

  getLogoState() {
    return this.toggleLogo$.asObservable();
  }
}
