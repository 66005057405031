import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export interface TokenResponse {
  token: string;
  juice_id: string;
  email: string;
  first_name: string;
  last_name: string;
  tos_accepted: boolean;
}

export interface Account {
  juice_id: string;
  email: string;
  first_name: string;
  last_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public juiceId: string;
  private token: string;
  private currentAccount: Account;
  private baseUrl = environment.apiUrl;

  constructor(private storage: Storage, private http: HttpClient) {}

  async saveAccount(tokenResponse: TokenResponse) {
    this.token = tokenResponse.token;
    this.juiceId = tokenResponse.juice_id;
    this.currentAccount = {
      juice_id: tokenResponse.juice_id,
      email: tokenResponse.email,
      first_name: tokenResponse.first_name,
      last_name: tokenResponse.last_name,
    };
    await this.storage.set('token', tokenResponse.token);
    await this.storage.set('juiceId', this.juiceId);
  }

  getToken() {
    return this.token;
  }

  getTokenFromStorage() {
    return this.storage.get('token');
  }

  getCurrentAccount() {
    return this.currentAccount;
  }

  getJuiceId() {
    return this.storage.get('juiceId');
  }

  getAccountFromAPI(juiceId: string) {
    const endpoint = this.baseUrl + `/account/${juiceId}`;
    return this.http
      .get(endpoint, { withCredentials: true })
      .pipe(
        map((res: Account) => {
          this.currentAccount = res;
          return res;
        })
      )
      .toPromise();
  }

  deleteAccount(juice_id: string) {
    const endpoint = this.baseUrl + `/account/${juice_id}`;
    return this.http.delete(endpoint, { withCredentials: true });
  }

  sendEmailCode(user_id: string) {
    const endpoint = this.baseUrl + `/auth/email/otp`;
    return this.http.post(endpoint, { user_id });
  }

  verifyEmailCode(user_id: string, verification_code: string) {
    const endpoint = this.baseUrl + `/auth/otp/verify`;
    return this.http.post<Partial<TokenResponse>>(endpoint, {
      user_id,
      verification_code,
    });
  }

  async saveUserToken(token: string) {
    this.token = token;
    await this.storage.set('token', token);
  }

  async logout() {
    this.token = undefined;
    this.juiceId = undefined;
    await this.storage.clear();
  }
}
