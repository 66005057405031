import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { InputConfig } from 'src/app/shared/components/juice-input/juice-input.component';
import { AccountService } from 'src/app/shared/services/account/account.service';
import {
  Partner,
  PartnerService,
} from 'src/app/shared/services/partner/partner.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent implements OnInit {
  public showStepOne: boolean = true;
  public infoForm: FormGroup = new FormGroup({
    businessName: new FormControl(null, [Validators.required]),
    phoneNumber: new FormControl(null, [
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
    website: new FormControl(null),
    description: new FormControl(null),
  });

  public addressForm: FormGroup = new FormGroup({
    address1: new FormControl(null, [Validators.required]),
    address2: new FormControl(null),
    city: new FormControl(null, [Validators.required]),
    state: new FormControl(null, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.pattern('^[a-zA-Z ]*$'),
    ]),
    zipcode: new FormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern('^[0-9]*$'),
    ]),
    country: new FormControl(null, [Validators.required]),
  });

  public infoFormConfig: InputConfig[] = [
    {
      controlName: 'businessName',
      label: 'Business name',
      type: 'text',
      errorMessage: 'Business name is required',
    },
    {
      controlName: 'phoneNumber',
      label: 'Phone number',
      type: 'tel',
      minlength: 10,
      maxlength: 10,
      errorMessage: 'Phone number is required',
    },
    {
      controlName: 'website',
      label: 'Website',
      type: 'text',
      errorMessage: null,
    },
    {
      controlName: 'description',
      label: 'Description of what you do',
      type: 'textarea',
      errorMessage: null,
    },
  ];

  public addressConfig: InputConfig[] = [
    {
      controlName: 'address1',
      label: 'Address Line 1',
      type: 'text',
      errorMessage: 'Address line 1 is required',
    },
    {
      controlName: 'address2',
      label: 'Address Line 2',
      type: 'text',
      errorMessage: null,
    },
    {
      controlName: 'city',
      label: 'City',
      type: 'text',
      errorMessage: 'City is required',
    },
    {
      controlName: 'state',
      label: 'State',
      type: 'text',
      errorMessage: 'State is required, for eg. NY',
    },
    {
      controlName: 'zipcode',
      label: 'Zip Code',
      type: 'text',
      errorMessage: 'Zip Code is required, only 5 characters allowed',
    },
    {
      controlName: 'country',
      label: 'Country',
      type: 'text',
      errorMessage: 'Country is required',
    },
  ];
  public partner: Partner;

  private loader;

  constructor(
    private partnerService: PartnerService,
    private accountService: AccountService,
    private loadingController: LoadingController,
    private router: Router,
    private modalController: ModalController,
    private toast: ToastController
  ) {}

  async ngOnInit() {
    this.loader = await this.loadingController.create({
      spinner: 'crescent',
      message: null,
      cssClass: 'juice-loader',
      backdropDismiss: false,
    });
    this.partner = this.partnerService.currentPartner;
    this.setupPage();
  }

  setupPage() {
    if (Object.keys(this.partner).length) {
      this.infoForm.controls['businessName'].setValue(this.partner.name);
      this.infoForm.controls['phoneNumber'].setValue(this.partner.phone_number);
      this.infoForm.controls['website'].setValue(this.partner.website);
      this.infoForm.controls['description'].setValue(this.partner.description);
    }
  }

  async submitInfo() {
    // TODO bring back address when needed
    // if (this.showStepOne) {
    //   // validate info form
    //   if (this.validateInfoForm()) {
    //     this.showStepOne = false;
    //   }
    // } else {
    if (this.validateInfoForm()) {
      await this.loader.present();
      const payload = await this.constructPayload();

      if (Object.keys(this.partner).length) {
        // update existing partner

        this.partnerService
          .updatePartnerDetails(this.partner.partner_id, payload)
          .subscribe(
            async () => {
              await this.loader.dismiss();
              const toast = await this.toast.create({
                message: `Details updated successfully!`,
                duration: 5000,
                position: 'bottom',
                color: 'success',
              });
              toast.present();
              this.modalController.dismiss({ completed: true });
            },
            async (err) => {
              // show error toast
              const toast = await this.toast.create({
                message: `Something went wrong. ${err.error.message}. Please try again`,
                duration: 5000,
                position: 'bottom',
                color: 'danger',
              });
              toast.present();

              await this.loader.dismiss();
            }
          );
      } else {
        this.partnerService.createPartnerAccount(payload).subscribe(
          async () => {
            await this.loader.dismiss();
            const toast = await this.toast.create({
              message: `Details saved successfully!`,
              duration: 5000,
              position: 'bottom',
              color: 'success',
            });
            toast.present();
            this.modalController.dismiss({ completed: true });
          },
          async (err) => {
            const toast = await this.toast.create({
              message: `Something went wrong. ${err.error.message}. Please try again`,
              duration: 5000,
              position: 'bottom',
              color: 'danger',
            });
            toast.present();
            await this.loader.dismiss();
            // show error toast
          }
        );
      }
    }
  }

  goBack() {
    this.showStepOne = true;
  }

  private validateInfoForm() {
    this.infoForm.markAllAsTouched();
    return this.infoForm.valid;
  }

  private validateAddress() {
    this.addressForm.markAllAsTouched();
    return this.addressForm.valid;
  }
  private async constructPayload() {
    // const addressValue = this.addressForm.value;
    const infoValue = this.infoForm.value;
    const juiceId = await this.accountService.getJuiceId();

    return {
      // address: {
      //   type: 'business',
      //   address1: addressValue['address1'],
      //   address2: addressValue['address2'],
      //   city: addressValue['city'],
      //   state: addressValue['state'],
      //   zipcode: addressValue['zipcode'],
      //   country: addressValue['country'],
      // },
      business_type: 'business',
      name: infoValue['businessName'],
      phone_number: infoValue['phoneNumber'],
      website: infoValue['website'],
      photos: this.partnerService.profileImage
        ? [this.partnerService.profileImage]
        : undefined,
      juice_id: juiceId,
      description: infoValue['description'],
      email: this.accountService.getCurrentAccount().email,
    };
  }

  closeModal() {
    this.modalController.dismiss({ comleted: false });
  }
}
