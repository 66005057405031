// apiurl should not have / at the end
// app url will have / at the end

export const environment = {
  production: true,
  apiUrl: 'https://api.getyourjuice.app/dev',
  appUrl: 'https://dev.getyourjuice.app',
  stripeUrl:
    'pk_test_51JrYW0DWGBtt69OW1x4OitkY88vUFoKe8nfrc3MwDLLaobzo7lF0mpcZc3fwxEK44200RGV5nN7e0BV74FdCarYr00hLgBx0tG',
};
