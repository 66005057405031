import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import {
  PartnerConfig,
  PartnerService,
} from '../../services/partner/partner.service';

export const DayOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wendnesday',
  'Thursday',
  'Friday',
  'Saturday',
];
@Component({
  selector: 'app-edit-business-hours',
  templateUrl: './edit-business-hours.component.html',
  styleUrls: ['./edit-business-hours.component.scss'],
})
export class EditBusinessHoursComponent implements OnInit {
  @Input() dayOfWeek: number;
  @Input() configs: PartnerConfig[];
  public days = DayOfWeek;
  public todaysConfig: PartnerConfig[];

  public hoursForm = new FormGroup({
    open: new FormControl(),
    close: new FormControl(),
  });

  constructor(
    private modalController: ModalController,
    private partnerService: PartnerService,
    private toast: ToastController
  ) {}

  ngOnInit() {
    this.setupData();
    // add values to form group
  }

  setupData() {
    this.todaysConfig = this.configs.map((config) => {
      return {
        ...config,
        open: moment(config.open, 'HH:mm:ss').format(),
        close: moment(config.close, 'HH:mm:ss').format(),
      };
    });
  }

  addNewTime() {}

  close() {
    this.modalController.dismiss();
  }

  async changeOpen(config: PartnerConfig, newOpenTime) {
    if (moment(newOpenTime).isAfter(moment(config.close))) {
      const toast = await this.toast.create({
        message: `Open time cannot be greater than close time.`,
        duration: 5000,
        position: 'bottom',
        color: 'danger',
      });
      toast.present();
      return;
    }

    const body = { open: moment(newOpenTime).format('HH:mm:ss') };

    config.open = moment(newOpenTime).format();
    this.partnerService.updatePartnerConfig(config.config_id, body).subscribe(
      async (res) => {
        const toast = await this.toast.create({
          message: 'Time saved successfully',
          duration: 5000,
          position: 'bottom',
          color: 'success',
        });
        toast.present();
        // this.loadData();
      },
      async (err) => {
        const toast = await this.toast.create({
          message: `There was an error in saving the time. Please try again`,
          duration: 5000,
          position: 'bottom',
          color: 'danger',
        });
        toast.present();
      }
    );
  }

  async changeClose(config: PartnerConfig, newCloseTime) {
    if (moment(newCloseTime).isBefore(moment(config.open))) {
      const toast = await this.toast.create({
        message: `Close time cannot be less than open time.`,
        duration: 5000,
        position: 'bottom',
        color: 'danger',
      });
      toast.present();
      return;
    }

    const body = { close: moment(newCloseTime).format('HH:mm:ss') };

    config.close = moment(newCloseTime).format();
    this.partnerService.updatePartnerConfig(config.config_id, body).subscribe(
      async (res) => {
        const toast = await this.toast.create({
          message: 'Time saved successfully',
          duration: 5000,
          position: 'bottom',
          color: 'success',
        });
        toast.present();
        // this.loadData();
      },
      async (err) => {
        const toast = await this.toast.create({
          message: `There was an error in saving the time. Please try again`,
          duration: 5000,
          position: 'bottom',
          color: 'danger',
        });
        toast.present();
      }
    );
  }
}
