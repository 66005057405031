import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteGuardService } from './shared/services/route-guard/route-guard.service';

// first page should be login, check for login route

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  //   // canActivate: [AuthGuardService],
  // },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationPageModule
      ),
  },
  // {
  //   path: 'weather',
  //   loadChildren: () =>
  //     import('./weather/weather.module').then((m) => m.WeatherPageModule),
  // },
  // {
  //   path: 'activity',
  //   loadChildren: () =>
  //     import('./activities/activities.module').then(
  //       (m) => m.ActivitiesPageModule
  //     ),
  // },
  // {
  //   path: 'this-or-that',
  //   loadChildren: () =>
  //     import('./this-or-that/this-or-that.module').then(
  //       (m) => m.ThisOrThatPageModule
  //     ),
  // },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfilePageModule),
  },
  // {
  //   path: 'music-recommendation',
  //   loadChildren: () =>
  //     import('./music-recommendation/music-recommendation.module').then(
  //       (m) => m.MusicRecommendationPageModule
  //     ),
  // },
  {
    path: 'settings',
    canActivate: [() => inject(RouteGuardService).canActivate()],
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'calendar',
    canActivate: [() => inject(RouteGuardService).canActivate()],
    loadChildren: () =>
      import('./calendar/calendar.module').then((m) => m.CalendarPageModule),
  },
  {
    path: 'standalone-payments',
    canActivate: [() => inject(RouteGuardService).canActivate()],
    loadChildren: () =>
      import('./standalone-payments/standalone-payments.module').then(
        (m) => m.StandalonePaymentsPageModule
      ),
  },
  {
    path: 'onboarding',
    canActivate: [() => inject(RouteGuardService).canActivate()],
    loadChildren: () =>
      import('./onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [() => inject(RouteGuardService).canActivate()],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'product-management',
    canActivate: [() => inject(RouteGuardService).canActivate()],
    loadChildren: () =>
      import('./product-management/product-management.module').then(
        (m) => m.ProductManagementPageModule
      ),
  },
  {
    path: 'create-wizard',
    canActivate: [() => inject(RouteGuardService).canActivate()],
    loadChildren: () =>
      import('./create-wizard/create-wizard.module').then(
        (m) => m.CreateWizardPageModule
      ),
  },
  {
    path: 'book',
    loadChildren: () => import('./b2c/b2c.module').then((m) => m.B2cPageModule),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutPageModule),
  },
  {
    path: 'juice-letter',
    loadChildren: () =>
      import('./juice-letter/juice-letter.module').then(
        (m) => m.JuiceLetterPageModule
      ),
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./event-details/event-details.module').then(
        (m) => m.EventDetailsPageModule
      ),
  },  {
    path: 'practitioner',
    loadChildren: () => import('./practitioner/practitioner.module').then( m => m.PractitionerPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
