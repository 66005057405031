import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { AccountService } from './shared/services/account/account.service';
import { AppInitializerService } from './shared/services/app-initializer/app-initializer.service';
import { AuthGuardService } from './shared/services/auth-guard/auth-guard.service';
import { AuthInterceptorService } from './shared/services/auth-interceptor/auth-interceptor.service';
import { PartnerService } from './shared/services/partner/partner.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeUrl),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthGuardService, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitializerService) => () =>
        appInitService.initializeAppData(),
      deps: [AppInitializerService, AccountService, PartnerService, Storage],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
