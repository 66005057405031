import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { NavigationService } from 'src/app/shared/services/navigation/navigation.service';

import {
  Partner,
  PartnerConfig,
  PartnerService,
} from 'src/app/shared/services/partner/partner.service';
import * as moment from 'moment';
import { EditBusinessHoursComponent } from '../edit-business-hours/edit-business-hours.component';

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss'],
})
export class BusinessHoursComponent implements OnInit {
  // TODO add modal input
  public isModalOpen: boolean = false;
  private partner: Partner;
  private existingConfig: PartnerConfig[] = [];

  public days = [
    {
      name: 'Sun',
      day_of_week: 0,
      config: [],
    },
    {
      name: 'Mon',
      day_of_week: 1,
      config: [],
    },
    {
      name: 'Tue',
      day_of_week: 2,
      config: [],
    },
    {
      name: 'Wed',
      day_of_week: 3,
      config: [],
    },
    {
      name: 'Thu',
      day_of_week: 4,
      config: [],
    },
    {
      name: 'Fri',
      day_of_week: 5,
      config: [],
    },
    {
      name: 'Sat',
      day_of_week: 6,
      config: [],
    },
  ];

  public loading: boolean = true;

  constructor(
    private partnerService: PartnerService,
    private toast: ToastController,
    private modalController: ModalController
  ) {}

  async ngOnInit() {
    this.isModalOpen = !!(await this.modalController.getTop());
    this.partner = this.partnerService.currentPartner;
    this.loadData();
  }

  loadData() {
    this.partnerService.getPartnerConfig(this.partner.partner_id).subscribe(
      (res) => {
        this.resetConfig();
        this.existingConfig = res;

        this.existingConfig.map((dayconfig) => {
          this.days.forEach((day) => {
            if (day.day_of_week === dayconfig.day_of_week) {
              const configForDay = { ...dayconfig };
              configForDay.open = moment(configForDay.open, 'HH:mm:ss').format(
                'LT'
              );
              configForDay.close = moment(
                configForDay.close,
                'HH:mm:ss'
              ).format('LT');
              day.config.push(configForDay);
            }
          });
        });
        this.loading = false;
      },
      (err) => {
        // Error Toast
      }
    );
  }
  private resetConfig() {
    this.days.forEach((day) => {
      day.config = [];
    });
  }

  async closeModal() {
    this.modalController.dismiss({ completed: !!this.existingConfig.length });
  }

  async openEditModal(day_of_week: number) {
    const configForDay = this.existingConfig.filter((config) => {
      return config.day_of_week === day_of_week;
    });

    if (!configForDay.length) {
      const toast = await this.toast.create({
        message: `Please set day as open before changing hours.`,
        duration: 5000,
        position: 'bottom',
        color: 'danger',
      });
      toast.present();
      return;
    }

    const modal = await this.modalController.create({
      component: EditBusinessHoursComponent,
      componentProps: {
        configs: configForDay,
        dayOfWeek: day_of_week,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      this.loadData();
    });
  }

  toggledDay(event, dayOfWeek: number) {
    const newIsOpen = event.target.checked;

    const defaultHours = {
      day_of_week: dayOfWeek,
      partner_id: this.partner.partner_id,
      open: '10:00:00',
      close: '19:00:00',
    };

    if (newIsOpen) {
      // set to open, create new config for day of week
      this.partnerService.createPartnerConfig(defaultHours).subscribe(
        async (res) => {
          const toast = await this.toast.create({
            message: 'Time saved successfully',
            duration: 5000,
            position: 'bottom',
            color: 'success',
          });
          toast.present();
          this.loadData();
        },
        async (err) => {
          const toast = await this.toast.create({
            message: `There was an error in saving the time. Please try again`,
            duration: 5000,
            position: 'bottom',
            color: 'danger',
          });
          toast.present();
        }
      );
    } else {
      const foundConfig = this.existingConfig.filter((config) => {
        return config.day_of_week === dayOfWeek;
      });

      foundConfig.forEach((singleFoundConfig) => {
        this.partnerService
          .deletePartnerConfig(singleFoundConfig.config_id)
          .subscribe(
            async (res) => {
              const toast = await this.toast.create({
                message: 'Time deleted successfully',
                duration: 5000,
                position: 'bottom',
                color: 'success',
              });
              toast.present();
              this.loadData();
            },
            async (err) => {
              const toast = await this.toast.create({
                message: `There was an error in deleting the time. Please try again`,
                duration: 5000,
                position: 'bottom',
                color: 'danger',
              });
              toast.present();
            }
          );
      });
    }
  }
}
