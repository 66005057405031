<ng-container *ngIf="group" [formGroup]="group">
  <!-- <ion-item  class="ion-no-padding input-wrapper"> -->
  <ion-input
    #ionInput
    *ngIf="type !== 'textarea'"
    class="juice-input"
    fill="outline"
    [label]="label || config.label"
    labelPlacement="stacked"
    [formControlName]="controlName"
    [type]="type"
    [inputmode]="inputmode"
    [placeholder]="placeholder"
    [maxlength]="maxlength"
    [minlength]="minlength"
    [autocomplete]="config?.autocomplete"
    [errorText]="errorMessage"
    [helperText]="helperText || config?.helperText"
  ></ion-input>
  <ion-textarea
    *ngIf="type === 'textarea'"
    class="juice-input juice-textarea"
    [formControlName]="controlName"
    [inputmode]="inputmode"
    [placeholder]="placeholder"
    auto-grow="true"
    fill="outline"
    shape="round"
    [autoGrow]="true"
    [label]="label || config.label"
    labelPlacement="stacked"
    [errorText]="errorMessage"
    [helperText]="helperText || config?.helperText"
  >
  </ion-textarea>
  <!-- <div
      class="form-error"
      *ngIf="group.get(controlName).touched && group.get(controlName).errors"
    >
      {{ errorMessage }}
    </div> -->
  <!-- </ion-item> -->
</ng-container>
