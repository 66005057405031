import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import {
  Partner,
  PartnerService,
} from 'src/app/shared/services/partner/partner.service';
import { environment } from 'src/environments/environment';
import { ImageUploadResponse } from '../../services/image/image.service';
import {
  Practitioner,
  PractitonerService,
} from '../../services/practitioner/practitoner.service';

@Component({
  selector: 'app-profile-picture-upload',
  templateUrl: './profile-picture-upload.component.html',
  styleUrls: ['./profile-picture-upload.component.scss'],
})
export class ProfilePictureUploadComponent implements OnInit {
  imageUrl: string;
  public loader: HTMLIonLoadingElement;
  private partner: Partner;
  private practitioner: Practitioner;
  private baseUrl = environment.apiUrl;
  constructor(
    private router: Router,
    private http: HttpClient,
    private partnerService: PartnerService,
    private practitionerService: PractitonerService,
    private loadingController: LoadingController,
    private modalController: ModalController
  ) {}

  async ngOnInit() {
    this.loader = await this.loadingController.create({
      spinner: 'crescent',
      message: null,
      cssClass: 'juice-loader',
      backdropDismiss: false,
    });
    this.partner = this.partnerService.currentPartner;
    this.practitioner = this.practitionerService.currentPractitioner;

    if (this.partner?.photos?.length && this.partner.photos[0]?.key) {
      this.imageUrl = this.partner.photos[0].location;
    } else if (this.partnerService.profileImage) {
      this.imageUrl = this.partnerService.profileImage.location;
    } else if (
      this.practitioner?.profile_picture?.length &&
      this.practitioner?.profile_picture
    ) {
      this.imageUrl = this.practitioner.profile_picture;
    } else if (this.practitionerService.profileImage) {
      this.imageUrl = this.practitionerService.profileImage.location;
    }
  }

  getFile(fileEvent) {
    // upload to aws
    this.loader.present();
    const file: File = fileEvent.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    this.http
      .post(`${this.baseUrl}/image/upload`, formData, {
        withCredentials: true,
      })
      .subscribe({
        next: (res: ImageUploadResponse) => {
          this.imageUrl = res.location;

          if (this.practitioner) {
            this.practitionerService.profileImage = res;
          } else {
            this.partnerService.profileImage = res;
          }
          // if partner exists, reset profile picture

          if (this.partner && Object.keys(this.partner)?.length) {
            const payload = {
              photos: [res],
            };
            this.partnerService
              .updatePartnerDetails(this.partner.partner_id, payload)
              .subscribe(() => {
                this.loader.dismiss();
              });
          } else if (this.practitioner) {
            const payload: Partial<Practitioner> = {
              profile_picture: res.location,
            };
            this.practitionerService
              .updatePractitioner(this.practitioner.practitioner_id, payload)
              .subscribe(() => {
                this.loader.dismiss();
              });
          } else {
            this.loader.dismiss();
          }
        },
        complete: () => {
          this.loader.dismiss();
        },
      });
  }

  closeModal() {
    this.modalController.dismiss({ completed: false });
  }

  completed() {
    if (this.imageUrl) {
      this.modalController.dismiss({ completed: true });
    }
  }
}
